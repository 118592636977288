import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptYellow from '../images/pt_yellow.gif'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Hexy Music &gt; Page not found" />

    <main id="content">
      <h2>&raquo; Page not found</h2>

      <h3><img src={ptYellow} alt="" /> The file you requested was not found on this website.</h3>
      <p>The page you requested was not found. <br />
      Please try one of the other links.</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>
)

export default NotFoundPage
